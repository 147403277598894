export function faqFunc() {

	let faqIndex = $("#faqTop").length;

	if(faqIndex){
		let masterBtn = $("#allControlBtn")
		let targetBtn = $(".faqList .question");
		let toggleTarget = $(".faqList .answerBox");
		targetBtn.on('click', function () {
			$(this).next(".answerBox").toggleClass('isOpen');
			$(this).next(".answerBox").stop().slideToggle(500);

			if($(this).next(".answerBox").hasClass("isOpen")){
				$(this).addClass("isOpen")
			}else{
				$(this).removeClass("isOpen")
			}
			
			let targetCount = $(".faqList .question").length;
			let toggleTargetCount = $(".faqList .answerBox.isOpen").length;

			if(toggleTargetCount === 0){
				masterBtn.removeClass("isOpen");
				masterBtn.text("Open All");
			}

			if(targetCount === toggleTargetCount){
				masterBtn.addClass("isOpen");
				masterBtn.text("Close All");
			}
		});

		masterBtn.on('click', function () {
			if($(this).hasClass("isOpen")){
				targetBtn.removeClass("isOpen");
				toggleTarget.removeClass("isOpen");
				toggleTarget.stop().slideUp(500);
				$(this).removeClass("isOpen");
				$(this).text("Open All");
			}else{
				targetBtn.addClass("isOpen");
				toggleTarget.addClass("isOpen");
				toggleTarget.stop().slideDown(500);
				$(this).addClass("isOpen");
				$(this).text("Close All");
			}
		});
	}
}