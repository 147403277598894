//トップページ　キービジュアルスライダー
export function newsFunc() {
	let selection = $('#selectionList').length;
  
	if (selection) {
		let resizecheck = $('#responsibleCheck').width();
		
		let cateToggleBtn = $('#cateToggleBtn');
		$(window).on('resize', function () {
			resizecheck = $('#responsibleCheck').width();
	  
			//アコーディオン初期化
			if (resizecheck == '2') {
			  cateToggleBtn.next('dd').css('display','none');
			} else if (resizecheck == '1') {
			  if (cateToggleBtn.hasClass('isOpen')) {
				cateToggleBtn.removeClass('isOpen');
			  }
			  cateToggleBtn.next('dd').css('display','block');
			}
		  });
	  
		  //アコーディオン初期化
		  if (resizecheck == '2') {
			cateToggleBtn.next('dd').css('display','none');
		  } else if (resizecheck == '1') { 
			if (cateToggleBtn.hasClass('isOpen')) {
			  cateToggleBtn.removeClass('isOpen');
			}
			cateToggleBtn.next('dd').css('display','block');
		  }
	  
		  let archiveToggleBtn = $('#archiveToggleBtn');
		  let topicsFlg = 0;
		  archiveToggleBtn.on('click', function () {
			if (resizecheck == '2') {
			  $(this).stop().toggleClass('isOpen');
			  $(this).find("dd").stop().slideToggle(500);
			}
		  });
	  
		  //マウスを乗せたら発動
		  $('#archiveToggleBtn').hover(function () {
			if (resizecheck == '1') {
			  $(this).find('dd').stop().slideToggle(500)
			  archiveToggleBtn.stop().addClass('isOpen');
			  //ここにはマウスを離したときの動作を記述
			}
		  }, function () {
			if (resizecheck == '1') {
			  $(this).find('dd').stop().slideToggle(500);
			  archiveToggleBtn.stop().removeClass('isOpen');
			  //ここにはマウスを離したときの動作を記述
			}
	  
		  });
	  
		  cateToggleBtn.on('click', function () {
			$(this).stop().toggleClass('isOpen');
			$(this).next("dd").stop().slideToggle(500);
		  });
	  
		  cateToggleBtn.on({
			'mouseover': function () {
			  if (responsibleCheck == '1') {
				topicsFlg = 1;
				$(this).toggleClass('isOpen');
				$(this).next("dd").stop().slideToggle(500);
			  }
			}
		});
	}
  }