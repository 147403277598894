//トップページ　キービジュアルスライダー
export function mizuharaFunc() {
	let mizuharaTop = $('#mizuharaTop').length;
  
	if (mizuharaTop) {
		//背景色の変更
		var secArr = new Array();
		var current = -1;
		/* ここに背景色を順に記述する*/
    //var secColor = new Array('rgba(253, 251, 249,.8)','rgba(234, 249, 255, .8)','rgba(230, 245, 255, .8)','rgba(227, 241, 255, .8)','rgba(227, 234, 255, .8)', 'rgba(255, 232, 229, 0.8)', 'rgba(254, 226, 223, 0.8)', 'rgba(255, 219, 214, 0.8)', 'rgba(255, 212, 206,.8)', 'rgba(226, 233, 252,.8)', 'rgba(255, 245, 245,.8)');
    //var secColor = new Array('rgba(253, 251, 249,.8)','rgba(234, 249, 255, .8)','rgba(230, 245, 255, .8)','rgba(227, 241, 255, .8)','rgba(227, 234, 255, .8)', 'rgba(255, 232, 229, 0.8)', 'rgba(254, 226, 223, 0.8)', 'rgba(255, 219, 214, 0.8)', 'rgba(255, 212, 206,.8)', 'rgba(255, 245, 245,.8)');
    var secColor = new Array('rgba(253, 251, 249,.8)','rgba(234, 249, 255, .8)','rgba(230, 245, 255, .8)','rgba(227, 241, 255, .8)','rgba(227, 234, 255, .8)', 'rgba(255, 232, 229, 0.8)', 'rgba(254, 226, 223, 0.8)', 'rgba(255, 219, 214, 0.8)', 'rgba(255, 212, 206,.8)', 'rgba(226, 233, 252,.8)', 'rgba(255, 245, 245,.8)');
				
		function chengeBG(secNum) {
		  if (secNum != current) {
			  current = secNum;
			  $('.mizuhara').css({
				  backgroundColor: secColor[current]
			  });
		  }
		}
		$(window).on('scroll', function () {
		  var scroll = $(window).scrollTop();
		  let winHight = window.innerHeight;
		
		  $('.gradation').each(function (i) {
			  secArr[i] = $(this).offset().top;
		  });
				
		  for (var i = secArr.length - 1; i >= 0; i--) {
			if (scroll > secArr[i] - winHight) {
			  chengeBG(i);
			  break;
			}
		  }
		});

    //クリックで音声を再生
    let my_audio = new Audio("/images/kiko_mizuhara/audio.mp3");
    // document.getElementById("audioBtn").onclick = function() {
    //   console.log("クリック")
    //     my_audio.currentTime = 0;
    //     my_audio.play();
    // }
	}
}


import {
  no_scroll,
  return_scroll
} from '../common/_common';

export function keyVisualMovie() {
	let mizuharaTop = $('#mizuharaTop').length;
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  
  if (mizuharaTop) {
    const close = document.getElementById('close');
    const videoCover = document.getElementById('mainviewArea');
    const videoWrapper = document.getElementById('videoWrapper');
    const playVideoId = document.getElementById('playVideo').getAttribute('data-url');
    const close02 = document.getElementById('close02');
    const videoCover02 = document.getElementById('videoControl');
    const videoWrapper02 = document.getElementById('videoWrapper02');
    //const playVideoId02 = document.getElementById('playVideo02').getAttribute('data-url');
    const close03 = document.getElementById('close03');
    const videoCover03 = document.getElementById('videoControl3');
    const videoWrapper03 = document.getElementById('videoWrapper03');
    const playVideoId03 = document.getElementById('playVideo03').getAttribute('data-url');
    let ytPlayer1,ytPlayer2,ytPlayer3;

    window.onYouTubeIframeAPIReady = function () {
      ytPlayer1 = new YT.Player('playVideo', {
        videoId: playVideoId,
        playerVars: {
          rel: 0,
          controls: 1,
          showinfo: 0,
          modestbranding: 1,
          iv_load_policy: 3,
          wmode: 'transparent',
					'origin': location.protocol + '//' + location.hostname + "/"
        },
        events: {
          'onStateChange': onPlayerStateChange,
          'onReady': onPlayerReady
        }
      });
      // ytPlayer2 = new YT.Player('playVideo02', {
      //   videoId: playVideoId02,
      //   playerVars: {
      //     rel: 0,
      //     controls: 1,
      //     showinfo: 0,
      //     modestbranding: 1,
      //     iv_load_policy: 3,
      //     wmode: 'transparent',
			// 		'origin': location.protocol + '//' + location.hostname + "/"
      //   },
      //   events: {
      //     'onStateChange': onPlayerStateChange2
      //   }
      // });
      ytPlayer3 = new YT.Player('playVideo03', {
        videoId: playVideoId03,
        playerVars: {
          rel: 0,
          controls: 1,
          showinfo: 0,
          modestbranding: 1,
          iv_load_policy: 3,
          wmode: 'transparent',
					'origin': location.protocol + '//' + location.hostname + "/"
        },
        events: {
          'onStateChange': onPlayerStateChange3
        }
      });
    }

    var ytReady = false;
    function onPlayerReady(e) {
      ytReady = true;
    }

    function onPlayerStateChange(event) {
      var ytStatus = event.target.getPlayerState();
      if (ytStatus == YT.PlayerState.ENDED) {
        return_scroll();
        videoWrapper.classList.remove('isPlay');
        ytPlayer1.stopVideo();
      }
    }

    function onPlayerStateChange2(event) {
      var ytStatus2 = event.target.getPlayerState();
      if (ytStatus2 == YT.PlayerState.ENDED) {
        return_scroll();
        videoWrapper02.classList.remove('isPlay');
        ytPlayer2.stopVideo();
      }
    }
    function onPlayerStateChange3(event) {
      var ytStatus3 = event.target.getPlayerState();
      if (ytStatus3 == YT.PlayerState.ENDED) {
        return_scroll();
        videoWrapper03.classList.remove('isPlay');
        ytPlayer2.stopVideo();
      }
    }

    function playVideo() {
      if (videoWrapper.classList.contains('isPlay')) {
        return_scroll();
        videoWrapper.classList.remove('isPlay');
        ytPlayer1.stopVideo();
      } else {
        no_scroll();
        videoWrapper.classList.add('isPlay');

        function ytCheck() {
          if(ytReady) {
            ytPlayer1.playVideo();
          } else {
            setTimeout(ytCheck, 200);
          }
        }
        ytCheck();
      }
    }

    function playVideo2() {
      if (videoWrapper02.classList.contains('isPlay')) {
        return_scroll();
        videoWrapper02.classList.remove('isPlay');
        ytPlayer2.stopVideo();
      } else {
        no_scroll();
        videoWrapper02.classList.add('isPlay');
        ytPlayer2.playVideo();
      }
    }

    function playVideo3() {
      if (videoWrapper03.classList.contains('isPlay')) {
        return_scroll();
        videoWrapper03.classList.remove('isPlay');
        ytPlayer3.stopVideo();
      } else {
        no_scroll();
        videoWrapper03.classList.add('isPlay');
        ytPlayer3.playVideo();
      }
    }

    videoCover.addEventListener('click', playVideo, false);
    close.addEventListener('click', playVideo, false);
    //videoCover02.addEventListener('click', playVideo2, false);
    //close02.addEventListener('click', playVideo2, false);
    videoCover03.addEventListener('click', playVideo3, false);
    close03.addEventListener('click', playVideo3, false);

  }

}