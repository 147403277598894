// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#scrPageTop');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight,position,subNavHeight,historyNavHeight,diff;
  let subNav = $('#subNav').length;
  let historyNav = $('#historyNav').length;
	let lazyImg = $('.lazyImg').length;

  if(!historyNav){
    $('a[href^="#"]:not(.notScroll)').each(function () {
      var pageUrl = location.href.split('#')[0];
      if (this.href.indexOf(pageUrl + '#') === 0) {
        var id = this.href.split('#')[1];
        $(this).on('click', function () {
          headerHeight = $('#header').outerHeight(true);
          subNavHeight = $('#subNav').outerHeight(true);
          if(subNav){
            position = $('#' + id).offset().top - headerHeight - subNavHeight;
          }else{
            position = $('#' + id).offset().top - headerHeight
          }
          $('body,html').animate({
            scrollTop: position
          }, 1000, 'easeInOutQuart');
          return false;
        });
      }
    });
    const url = $(location).attr('href');
    if (url.indexOf("?id=") != -1) {
      const id = url.split("?id=");
      const $target = $('#' + id[id.length - 1]);
      setTimeout(function(){
      if ($target.length) {
        $('#header').removeClass('changeColor');
        let headerHeight = $('#header').outerHeight(true);
        if ($('#subNav').length) {
          let subNavHeight = $('#subNav').outerHeight(true);
          let pos = $target.offset().top - headerHeight - subNavHeight;
          $("html, body").animate({
            scrollTop: pos
          }, 1000, 'easeInOutCubic');
        } else {
          let pos = $target.offset().top - headerHeight;
          $("html, body").animate({
            scrollTop: pos
          }, 1000, 'easeInOutCubic');
        }
      }
    },1000);
    }
  }
  
  // if(!historyNav && lazyImg){
  //   console.log('aaa');
  //   $('a[href^="#"]:not(.notScroll)').each(function () {
  //     var pageUrl = location.href.split('#')[0];
  //     if (this.href.indexOf(pageUrl + '#') === 0) {
  //       var id = this.href.split('#')[1];
  //       $(this).on('click', function (e) {
  //         headerHeight = $('#header').outerHeight(true);
  //         subNavHeight = $('#subNav').outerHeight(true);
  //         if(subNav){
  //           position = $('#' + id).offset().top - headerHeight - subNavHeight;
  //         }else{
  //           position = $('#' + id).offset().top - headerHeight
  //         }

  //         $.when(
  //           $('body,html').animate({
  //             scrollTop: position
  //           }, 1000, 'easeOutQuart'),
  //           e.preventDefault(),
  //         ).done(function() {
  //           if(subNav){
  //             diff = $('#' + id).offset().top - headerHeight - subNavHeight;
  //           }else{
  //             diff = $('#' + id).offset().top - headerHeight
  //           }

  //           if (diff === position) {
  //           } else {
  //             $("html, body").animate({
  //             scrollTop: diff
  //             }, 1000, "easeInQuart");
  //           }
  //         });
  //         return false;
  //       });
  //     }
  //   });
  // }

  if(historyNav){
    $('a[href^="#"]:not(.historyAd)').each(function () {
      var pageUrl = location.href.split('#')[0];
      if (this.href.indexOf(pageUrl + '#') === 0) {
        var id = this.href.split('#')[1];
        $(this).on('click', function () {
          headerHeight = $('#header').outerHeight(true);
          subNavHeight = $('#subNav').outerHeight(true);
          position = $('#' + id).offset().top - headerHeight;
          $('body,html').animate({
            scrollTop: position
          }, 1000, 'easeInOutQuart');
          return false;
        });
      }
    });

    $('.historyAd[href^="#"]').each(function () {
      var pageUrl = location.href.split('#')[0];
      if (this.href.indexOf(pageUrl + '#') === 0) {
        var id = this.href.split('#')[1];
        $(this).on('click', function () {
          headerHeight = $('#header').outerHeight(true);
          historyNavHeight = $('#historyNav').outerHeight(true);
          let resizecheck = $('#responsibleCheck').width();
            if (resizecheck == '2') {
              position = $('#' + id).offset().top - headerHeight - historyNavHeight;
            } else if (resizecheck == '1') {
              position = $('#' + id).offset().top - headerHeight;
            }
          $('body,html').animate({
            scrollTop: position
          }, 1000, 'easeInOutQuart');
          return false;
        });
      }
    });
  }

}