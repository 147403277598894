export function footerFunc() {
  // $('#loaderBg').delay(500).fadeOut(500);
  // $('#loader').fadeOut(500);

  // $('#loaderBg').fadeOut(600, function() {
  //   $('#loader').delay(200).fadeOut();
  // });

  // ローディング画面
  var images = document.getElementsByTagName('img'); // ページ内の画像取得
  var percent = document.getElementById('percent-text'); // パーセントのテキスト部分
  var gauge = document.getElementById('gauge'); // ゲージ
  var loading = document.getElementById('loading'); // ローディング要素
  var count = 0;
  var gaugeMax = 400; // ゲージの幅指定
  var current;
  var imgNum = images.length;

  // 画像の読み込み
  for (var i = 0; i < images.length; i++) {
    var img = new Image(); // 画像の作成
    // 画像読み込み完了したとき
    img.onload = function () {
      count += 1;
    }
    // 画像読み込み失敗したとき
    img.onerror = function () {
      count += 1;
    }
    img.src = images[i].src; // 画像にsrcを指定して読み込み開始
  };

  // ローディング処理
  var nowLoading = setInterval(function () {
    // 現在の読み込み具合のパーセントを取得
    current = Math.floor(count / imgNum * 100);
    // パーセント表示の書き換え
    percent.innerHTML = current;
    percent.style.transform = 'translateX(' + current + '%)';

    // ゲージの変更
    gauge.style.width = Math.floor(gaugeMax / 100 * current) + 'px';
    // 全て読み込んだ時

    if (count >= imgNum || count == imgNum) {
      // ローディング要素の非表示
      $('#loading').delay(200).fadeOut(2000);
      //loading.style.display = 'none';
      // ローディングの終了
      clearInterval(nowLoading);
    }
  }, 100);
}