const header = document.getElementById("header");

const indexTop = $('#indexTop').length;
const ageConfirmTop = $("#ageConfirmTop").length;
const anniversaryTop = $("#anniversaryTop").length;

const contentsPage = $('#contentsTop').length;
const subNavPage = $('#subNav').length;
const subNav = document.getElementById('subNav');

const navBtn = $('#navBtn');
const navMenu = $('#navMenu');

let menuOpenFlg = 0;
let scrTopFlg = 0;
let scrollPosition;

export function headerNavFunc() {
  navBtn.on('click', function () {
    navBtn.toggleClass('isOpen');
    if (navBtn.hasClass('isOpen')) {
      scrollPosition = $(window).scrollTop();
      menuOpenFlg = 1;
      navMenu.addClass('isShow');
      header.classList.remove('changeColor');
      $('.headerContents').addClass('isShow');
      $('body').addClass('fixedBody').css({
        'top': -scrollPosition
      });
    } else {
      menuOpenFlg = 0;
      navMenu.removeClass('isShow');
      $('.headerContents').removeClass('isShow');
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, scrollPosition);
      if (scrTopFlg == 1) {
        header.classList.add('changeColor');
      }
    }
  });

  function indexHeaderScrFunc(){
    let scrTop = $(window).scrollTop();
    if (menuOpenFlg == 0) {
      if (scrTop < 20) {
        scrTopFlg = 1;
        header.classList.remove('changeHeight');

        if (subNavPage) {
          subNav.classList.remove('notTop');
        }

      } else {
        scrTopFlg = 0;
        header.classList.add('changeHeight');

        if (subNavPage) {
          subNav.classList.add('notTop');
        }
      }
    }
  }
  function bottomHeaderScrFunc(){
    let scrTop = $(window).scrollTop();
    if (menuOpenFlg == 0) {
      if (scrTop < 20) {
        if(!contentsPage){
          header.classList.add('changeColor');
          header.classList.remove('changeHeight');
        }

        if (subNavPage) {
          subNav.classList.remove('notTop');
        }
      } else {
        if(!contentsPage){
          header.classList.remove('changeColor');
          header.classList.add('changeHeight');
        }

        if (subNavPage) {
          subNav.classList.add('notTop');
        }
      }
    }
  }

  console.log(indexTop)
  if (indexTop || anniversaryTop) {
    scrTopFlg = 1;

    indexHeaderScrFunc();
    $(window).on('scroll', function () {
      indexHeaderScrFunc();
    });
  }else if(ageConfirmTop){
    null
  }else {
    bottomHeaderScrFunc();
    $(window).on('scroll', function () {
      bottomHeaderScrFunc();
    });
  }
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}