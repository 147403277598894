import anime from 'animejs/lib/anime.es.js';
export function anniversaryFunc() {
	let anniversaryTop = $("#anniversaryTop").length;

	if(anniversaryTop){
		const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms));

		wait(0)
		.then(() => {
			function flowerFunc(){
				anime({
					targets:".fvFlower",
					opacity: 1,
					scale: 1,
					translateY:0,
					translateX:0,
					translateZ:0,
					delay:function(){
						return anime.random(0,500);
					},
					duration: 5000,
					fill: 'forwards'
				});
			}flowerFunc();
			return wait(1500)
		})
		.then(() => {
			function animateFadeInFunc(){
				anime({
					targets:".itemAnime",
					opacity: 1,
					delay:function(){
						return anime.random(0,1500);
					},
					duration: 5000,
					fill: 'forwards'
				});
			}animateFadeInFunc();
			return wait(1000)
		})
		.then(() => {
			function animateFunc(){
				anime({
					targets:".itemAnime",
					translateX:function(){
						return anime.random(-10,10);
					},
					translateY:function(){
						return anime.random(-10,10);
					},
					delay:function(){
						return anime.random(0,300);
					},
					duration: 3000,
					easing: 'linear',
					complete: animateFunc,
				});
			}animateFunc();
		})

		$('.gallery').slick({
			infinite: true,
			arrows: true,
			prevArrow: '<img src="/images/common/mainslide_back.svg" class="slide-arrow prev-arrow">',
			nextArrow: '<img src="/images/common/mainslide_next.svg" class="slide-arrow next-arrow">',
			lazyLoad: 'progressive',
			speed: 800,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						asNavFor: '.thumbnail',
					},
				},
			],
		});
		
		$('.thumbnail').slick({
			infinite: true,
			slidesToShow: 9,
			focusOnSelect: true,
			asNavFor: '.gallery',
			lazyLoad: 'progressive',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						arrows:false,
						centerMode: true,
						centerPadding: "8%",
					},
				},
			],
		});

		if (window.matchMedia('(min-width:768px)').matches) {
			$('.gallery').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				var index = nextSlide;
				$(".thumbnail .slick-slide").removeClass("slick-current").eq(index).addClass("slick-current");
			});
		}

		$('.subSlide').slick({
			infinite: true,
			arrows: true,
			slidesToShow: 5,
			speed: 800,
			prevArrow: '<img src="/images/common/mainslide_back.svg" class="slide-arrow prev-arrow">',
			nextArrow: '<img src="/images/common/mainslide_next.svg" class="slide-arrow next-arrow">',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					},
				},
			],
		});


		function sideNavMoveFunc(){
			let headerHeight = $("#header").innerHeight();
			let mainHeight = $(".historyList").innerHeight();
			let sideInnerHeight = $("#indexList").innerHeight();
			let windowHeight = $(window).innerHeight();
	
			if(sideInnerHeight >= windowHeight){
				let y = sideInnerHeight - windowHeight
				let bStart = $(".historyList").offset().top - headerHeight;
				let bStop = $(".historyList").offset().top + mainHeight - headerHeight ;
				let bsy = bStop - bStart
				let ba = y / bsy
				$(window).on('scroll', function () {
					let start = $(".historyList").offset().top - headerHeight;
					let stop = $(".historyList").offset().top + mainHeight - headerHeight;
					let scroll = $(window).scrollTop()
					let sy = scroll - start 
					let transform = sy * ba
					if(scroll > start && scroll < stop){
						$("#historyNav").css({
							 "transform":`translateY(-${transform}px)`
						});
					}
					
				});
			}
		}


		let resizecheck = $('#responsibleCheck').width();

		  
		if (resizecheck == '2') {
			$("#historyNav").css({
				"transform":"translateY(0px)",
			  });
		} else if (resizecheck == '1') {
			sideNavMoveFunc();
		}

		window.addEventListener('resize', () => {
			resizecheck = $('#responsibleCheck').width();
		
			//アコーディオン初期化
			if (resizecheck == '2') {
				$("#historyNav").css({
					"transform":"translateY(0px)"
				  });
			} else if (resizecheck == '1') {
				sideNavMoveFunc();
			}
		  }, false);


		const boxes = document.querySelectorAll(".secTop")
		const options = {
			root: null, 
			rootMargin: "0px 0px -25% 0px",
			threshold: 0 
		};
		const observer = new IntersectionObserver(doWhenIntersect, options);
		boxes.forEach(box => {
			observer.observe(box);
		});

		/**
		 * 交差したときに呼び出す関数
		 * @param entries
		 */
		function doWhenIntersect(entries) {
			entries.forEach(entry => {
			if (entry.isIntersecting) {
				activateIndex(entry.target);
			}
			});
		}

		/**
		 * 目次の色を変える関数
		 * @param element
		 */
		function activateIndex(element) {
			const currentActiveIndex = document.querySelector("#indexList .current");
			if (currentActiveIndex !== null) {
				currentActiveIndex.classList.remove("current");
			}
			const newActiveIndex = document.querySelector(
				`a[href='#${element.id}']`
			);
			newActiveIndex.classList.add("current");
		}

		          /* ----------------------------------------------------------------------------------
    モーダルウィンドウ
    ---------------------------------------------------------------------------------- */
    var detailModalFunc = {
		init: function(onLoad) {
		  if(onLoad) this._init();
		},
		_init: function() {
		  // 設定
		  var animateSpeed = 2000;
		  var closeElem = '.close a';
		  var btnCloseElem = '.closeBtn';
		  var mainModalContent = '.detailModalContent';
		  var myModalHeight;
		  var pointScrollY;
		  var currentScrollY;
	
		  $(window).scroll(function() {
			pointScrollY = $(window).scrollTop();
		  });
	
		  // スライドされる高さの調整
		   $(window).on('load resize', function() {
			 var windowHeight = $(window).outerHeight();
			 $(mainModalContent).find('.modalInner').css({'height':windowHeight}); //高さを出すスクロールを表示
		   });
	
		  $(mainModalContent).each(function(i) {
			i++;
			//console.log(i);
			$('.modal' + i).on('click', function() {
			  // 背景固定
			  $(`#modal${i} img`).attr('src',$(`#modal${i} img`).data('src')).attr('srcset',$(`#modal${i} img`).data('srcset'));

			   currentScrollY = $(window).scrollTop();
			  $('body').css({
				position: 'fixed',
				width: '100%',
				top: -1 * currentScrollY,
			  });
	
			  $('#modal' + i).fadeIn(animateSpeed);
	
			  // 上下縦横中央寄せに実行
			  var windowHeight = $(window).outerHeight(); // 縦方向の余白を保つ
			  myModalHeight = $('#modal' + i).outerHeight();
			  if(windowHeight <= myModalHeight) {
				myModalHeight = windowHeight;
				$(mainModalContent).css({'height':'100%'});
			  }
			  centeringModalSyncer(myModalHeight);
			});
	
			function closeAction() {
			  // 背景を固定を解除
			  $('body').attr({style:''});
			  $('html,body').animate({scrollTop:currentScrollY},1);
			  $(mainModalContent).stop().fadeOut(animateSpeed);
			}
	
			// クリックでモーダルをクローズ
			$(closeElem).on('click', function() {
			  closeAction();
			});
		  });
		  $(window).on('resize', centeringModalSyncer);
	
		  // 上下縦横中央寄せの関数
		  function centeringModalSyncer(myModalHeight) {
			$(mainModalContent).css({
			  'left': '0px',
			  'top': '0px',
			});
		  }
		}
	  }
	  detailModalFunc.init(true);
	}
}