//トップページ　キービジュアルスライダー
export function shopFunc() {
	let shopTop = $('#shopTop').length;
	let storeSearchTop = $('#storeSearchTop').length;
	let irohaStoreTop = $('#irohaStoreTop').length;
	const storeSearch = $('#storeSearch');
	let otherStoreList = $("#hideBox");
  
	if (shopTop) {
		let resizecheck = $('#responsibleCheck').width();
		let shopAccordionBtn = $("#shopToggleBtn");
	
		// window.addEventListener('resize', () => {
		//   resizecheck = $('#responsibleCheck').width();
	
		//   //アコーディオン初期化
		//   if (resizecheck == '2') {
		// 	otherStoreList.css('display','none');

		//   } else if (resizecheck == '1') {
		// 	otherStoreList.css('display','none');
		//   }
		// }, false);
	
		// //アコーディオン初期化
		// if (resizecheck == '2') {
		//   otherStoreList.css('display','none');
		// } else if (resizecheck == '1') {
		//   otherStoreList.css('display','none');
		// }

		otherStoreList.slideUp(500);
		shopAccordionBtn.on('click', function () {
			shopAccordionBtn.toggleClass('isOpen');
			otherStoreList.stop().slideToggle(500);

			if(shopAccordionBtn.hasClass("isOpen")){
				shopAccordionBtn.text("Close")
			}else{
				shopAccordionBtn.text("Open")
			}
		});

			//背景色の変更
		var secArr = new Array();
		var current = -1;
		/* ここに背景色を順に記述する*/
		var secColor = new Array('rgba(242, 252, 253,.8)', 'rgba(245, 253, 254,.8)','rgba(242, 252, 253,.8)', 'rgba(245, 253, 254,.8)');
				
		function chengeBG(secNum) {
		  if (secNum != current) {
			  current = secNum;
			  $('.shopArea').css({
				  backgroundColor: secColor[current]
			  });
		  }
		}
		$(window).on('scroll', function () {
		  var scroll = $(window).scrollTop();
		  let winHight = window.innerHeight;
		
		  $('.gradation').each(function (i) {
			  secArr[i] = $(this).offset().top;
		  });
				
		  for (var i = secArr.length - 1; i >= 0; i--) {
			if (scroll > secArr[i] - winHight) {
			  chengeBG(i);
			  break;
			}
		  }
		});
	}

	if (storeSearchTop) {
		//背景色の変更
		var secArr = new Array();
		var current = -1;
		/* ここに背景色を順に記述する*/
		var secColor = new Array('rgba(242, 252, 253,.8)', 'rgba(245, 253, 254,.8)','rgba(242, 252, 253,.8)', 'rgba(245, 253, 254,.8)');
	
		function chengeBG(secNum) {
		  if (secNum != current) {
			  current = secNum;
			  $('.shopArea').css({
				  backgroundColor: secColor[current]
			  });
		  }
		}
		$(window).on('scroll', function () {
		  var scroll = $(window).scrollTop();
		  let winHight = window.innerHeight;
		
		  $('.gradation').each(function (i) {
			  secArr[i] = $(this).offset().top;
		  });
	
		  for (var i = secArr.length - 1; i >= 0; i--) {
			if (scroll > secArr[i] - winHight) {
			  chengeBG(i);
			  break;
			}
		  }
		});
	}

	if (irohaStoreTop) {
		//背景色の変更
		var secArr = new Array();
		var current = -1;
		/* ここに背景色を順に記述する*/
		var secColor = new Array('rgba(242, 252, 253,.8)', 'rgba(242, 242, 249,.8)','rgba(255, 250, 240,.8)');
	
		function chengeBG(secNum) {
		  if (secNum != current) {
			  current = secNum;
			  $('.shopArea').css({
				  backgroundColor: secColor[current]
			  });
			//   $('.btn01').css({
			// 	backgroundColor: secColor[current]
			//   });
		  }
		}
		$(window).on('scroll', function () {
		  var scroll = $(window).scrollTop();
		  let winHight = window.innerHeight;
		
		  $('.gradation').each(function (i) {
			  secArr[i] = $(this).offset().top;
		  });
	
		  for (var i = secArr.length - 1; i >= 0; i--) {
			if (scroll > secArr[i] - winHight) {
			  chengeBG(i);
			  break;
			}
		  }
		});


		let resizecheck = $('#responsibleCheck').width();
	  
		window.addEventListener('resize', () => {
		  resizecheck = $('#responsibleCheck').width();
	  
		  //アコーディオン初期化
			newsSlideFunc();
		}, false);

		function newsSlideFunc(){
			if (resizecheck == '2') {
				$("#newsSlide").not('.slick-initialized').slick({
					autoplay: false,
					autoplaySpeed: 10000,
					speed: 800,
					centerMode:true,
					centerPadding:"10%",
					prevArrow: '<img src="/images/common/mainslide_back.svg" class="slide-arrow prev-arrow">',
					nextArrow: '<img src="/images/common/mainslide_next.svg" class="slide-arrow next-arrow">',
				});
			  }else{
				$("#newsSlide.slick-initialized").slick('unslick');
			  }
		}newsSlideFunc();
	}

	function searchFunc() {
		const searchBtn = $('#searchBtn');
		const store = document.getElementById("storeSearch")
		let flag = false;
		$('#area').change(function () {
		  let areaSelected = store.area.selectedIndex;
		  if (areaSelected > 0) {
			flag = true;
		  } else {
			flag = false;
		  }
		  if (flag) {
			searchBtn.prop('disabled', false);
		  } else {
			searchBtn.prop('disabled', true);
		  }
		});
	  }

	  if (storeSearch.length) {
		searchFunc();
	  }
  }