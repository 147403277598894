//トップページ　キービジュアルスライダー
import slick from 'slick-carousel';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/autocomplete';

export function contentsFunc() {
  let contentsTop = $('#contentsTop').length;
  let contentsIndex = $('.contentsIndex').length;
  let contentsArticles = $('.contentsArticles').length;
  let itemToggle = $('#toggleBtn').length;

  if (contentsTop) {
    //背景色の変更
    var secArr = new Array();
    var current = -1;
    /* ここに背景色を順に記述する*/
    if(contentsIndex){
      var secColor = new Array('rgba(255, 249, 243,.8)', 'rgba(255, 243, 231,.8)', 'rgba(255, 249, 243,.8)', 'rgba(255, 243, 231,.8)', 'rgba(255, 249, 243,.8)');
    }
    if(contentsArticles){
      var secColor = new Array( 'rgba(255, 249, 243,.8)' , 'rgba(255, 243, 231,.8)', 'rgba(255, 243, 231,.8)', 'rgba(255, 249, 243,.8)');
    }

    function chengeBG(secNum) {
      if (secNum != current) {
          current = secNum;
          $('.contentsArea').css({
              backgroundColor: secColor[current]
          });
      }
    }
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      let winHight = window.innerHeight;
    
      $('.gradation').each(function (i) {
        secArr[i] = $(this).offset().top;
      });

      for (var i = secArr.length - 1; i >= 0; i--) {
        if (scroll > secArr[i] - winHight) {
          chengeBG(i);
          break;
        }
      }
    });

    $('.slideArea').slick({
      loop: true,
      autoplay: false,
      autoplaySpeed: 5000,
      speed: 1200,
      dots: true,
      infinite: true,
      prevArrow: '<img src="/images/common/img_prev_arrow.png" class="slide-arrow next-arrow">',
      nextArrow: '<img src="/images/common/img_next_arrow.png" class="slide-arrow prev-arrow">',
    });

    let resizecheck = $('#responsibleCheck').width();
    let accordionBtn = $('#accordionBtn');
    let searchBtn = $('#searchAreaBack');

    window.addEventListener('resize', () => {
      resizecheck = $('#responsibleCheck').width();

      //アコーディオン初期化
      if (resizecheck == '2') {
        accordionBtn.next('dd').css('display','none');
        searchBtn.next().css('display','block');
      } else if (resizecheck == '1') {
        accordionBtn.removeClass('isOpen');
        accordionBtn.next('dd').css('display','block');
        searchBtn.next().css('display','none');
      }
    }, false);

    //アコーディオン初期化
    if (resizecheck == '2') {
      accordionBtn.next('dd').css('display','none');
      searchBtn.next().css('display','block');
    } else if (resizecheck == '1') {
      accordionBtn.removeClass('isOpen');
      accordionBtn.next('dd').css('display','block');
      searchBtn.next().css('display','none');
    }
    
    accordionBtn.on('click', function () {
      $(this).toggleClass('isOpen');
      $(this).next().stop().slideToggle(500);
    });
    
    searchBtn.on('click', function () {
      $(this).toggleClass('isOpen');
      $(this).next().stop().slideToggle(500);
    });

    const fixedElm = document.getElementById('header');
    let subElm = $('.contentsSubNav');
    let scrollPoint = 0;
    let lastPoint = 0;

    window.addEventListener("scroll",function(){
      
      scrollPoint = window.scrollY;
      if (scrollPoint < 20){
        fixedElm.classList.remove('changeStyle');
        subElm.removeClass('minTop scrollDown');
      }else if(scrollPoint > 20 && scrollPoint > lastPoint){
        // 下スクロール
        fixedElm.classList.add('fixed-hide');
        subElm.removeClass('minTop');
        subElm.addClass('scrollDown');
      }else if(scrollPoint > 20 && scrollPoint < lastPoint){
        // 上スクロール
        fixedElm.classList.remove('fixed-hide');
        fixedElm.classList.add('changeStyle');
        subElm.addClass('minTop');
      }
      
      lastPoint = scrollPoint;
    });


    if(itemToggle){

      let btn = $("#toggleBtn")
      if($.cookie("open-panel")){
        btn.addClass('isOpen');
        btn.find("span").text("Open")
        $(".cateNavList li:nth-child(n + 5)").hide();
      }
      btn.click(function() {
        if($.cookie("open-panel")){
          $(this).removeClass('isOpen');
          $(this).find("span").text("Close")
          $(".cateNavList li:nth-child(5),.cateNavList li:nth-child(6),.cateNavList li:nth-child(7),.cateNavList li:nth-child(8)").delay(100).slideDown(100)
          $(".cateNavList li:nth-child(9),.cateNavList li:nth-child(10),.cateNavList li:nth-child(11),.cateNavList li:nth-child(12)").delay(200).slideDown(100)
          $(".cateNavList li:nth-child(n+13)").delay(300).slideDown(100);
          $.removeCookie("open-panel" , { path: "/" });
        } else {
          $(this).addClass('isOpen');
          $(this).find("span").text("Open")
          $(".cateNavList li:nth-child(5),.cateNavList li:nth-child(6),.cateNavList li:nth-child(7),.cateNavList li:nth-child(8)").delay(300).slideUp(100)
          $(".cateNavList li:nth-child(9),.cateNavList li:nth-child(10),.cateNavList li:nth-child(11),.cateNavList li:nth-child(12)").delay(200).slideUp(100)
          $(".cateNavList li:nth-child(n+13)").delay(100).slideUp(100);
          $.cookie("open-panel" , "open" , { path: "/" });
        }
      return false;
      });
    }

  /* ----------------------------------------------------------------------------------
	　youtube動画スタイル動的に追加
	---------------------------------------------------------------------------------- */
    $(".contentsWrap .singleContent iframe").each(function() {
      var iframe = $(this).prop("src");
        if( iframe.match(/youtube.com/) ){
          $(this).wrap('<div class="movie_wrap"></div>');
        }
      });


      let getUrl = location.host;
      var wpAjaxUrl;
      
      if(getUrl == 'iroha-brandsite-renewal.test'){
        wpAjaxUrl = 'http://iroha-brandsite-renewal.test/wp/wp-admin/admin-ajax.php';
      }else if(getUrl == 'localhost:3000'){
        wpAjaxUrl = 'http://localhost:3000/wp/wp-admin/admin-ajax.php';
      }else if(getUrl == 'iroha-tenga.com'){
        wpAjaxUrl = 'https://iroha-tenga.com/wp/wp-admin/admin-ajax.php';
      }else if(getUrl == 'dev-iroha.acceptee-tenga.com'){
        wpAjaxUrl = 'http://dev-iroha.acceptee-tenga.com/wp/wp-admin/admin-ajax.php';
      }else if(getUrl == '192.168.11.4:3000'){
        wpAjaxUrl = 'http://192.168.11.4:3000/wp/wp-admin/admin-ajax.php';
      }
  
      let autocompletePositionAt,autocompletePositionMy;
      if (resizecheck == '2') {
        autocompletePositionMy = "left bottom";
        autocompletePositionAt = "left top-5";
      } else{
        autocompletePositionMy = "right top";
        autocompletePositionAt = "right bottom+5";
      }

      $("#contentsSearch").autocomplete({
        source: function(request, response){
          $.ajax({
            url: wpAjaxUrl,
            type:'POST',
            method: 'post',
            dataType: "json",
            data: {
              'action': 'view_sitename',
              param1: request.term
            },
            success: function(data) {
              response(data);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                response(['']);
            },
          });
        },
        select: function(event, ui) {
          this.value = ui.item.value;
          location.href = '/contents/?s=' + ui.item.value;
          return false;
        },
        open: function (result) {
          if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
              $('.ui-autocomplete').off('menufocus hover mouseover');
          }
        },
        delay:500,
        appendTo: "#searchFormBox",
        position:{
          my : autocompletePositionMy,
          at : autocompletePositionAt,
          using: function(pos,info) {
            $(this).css(pos);
            $(window).on('scroll', function () {
              $(this).css(pos);
            });
            var posV = (info.vertical == 'top'? 'btm' : 'top');
            var posH = (info.horizontal == 'left'? 'right' : 'left');
            $(this).removeClass('disp-top disp-btm disp-left disp-right');
            $(this).addClass('disp-' + posV + ' disp-' + posH);
          }
        },
      });
  }
}